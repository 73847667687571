exports.components = {
  "component---src-pages-ai-products-bionic-suite-jsx": () => import("./../../../src/pages/ai-products/bionic-suite.jsx" /* webpackChunkName: "component---src-pages-ai-products-bionic-suite-jsx" */),
  "component---src-pages-ai-products-jsx": () => import("./../../../src/pages/ai-products.jsx" /* webpackChunkName: "component---src-pages-ai-products-jsx" */),
  "component---src-pages-ai-products-kronos-jsx": () => import("./../../../src/pages/ai-products/kronos.jsx" /* webpackChunkName: "component---src-pages-ai-products-kronos-jsx" */),
  "component---src-pages-ai-products-osteocheck-jsx": () => import("./../../../src/pages/ai-products/osteocheck.jsx" /* webpackChunkName: "component---src-pages-ai-products-osteocheck-jsx" */),
  "component---src-pages-ai-products-prodigi-jsx": () => import("./../../../src/pages/ai-products/prodigi.jsx" /* webpackChunkName: "component---src-pages-ai-products-prodigi-jsx" */),
  "component---src-pages-ai-products-protocall-jsx": () => import("./../../../src/pages/ai-products/protocall.jsx" /* webpackChunkName: "component---src-pages-ai-products-protocall-jsx" */),
  "component---src-pages-borderless-radiology-jsx": () => import("./../../../src/pages/borderless-radiology.jsx" /* webpackChunkName: "component---src-pages-borderless-radiology-jsx" */),
  "component---src-pages-company-about-us-jsx": () => import("./../../../src/pages/company/about-us.jsx" /* webpackChunkName: "component---src-pages-company-about-us-jsx" */),
  "component---src-pages-company-careers-jsx": () => import("./../../../src/pages/company/careers.jsx" /* webpackChunkName: "component---src-pages-company-careers-jsx" */),
  "component---src-pages-company-job-description-jsx": () => import("./../../../src/pages/company/job-description.jsx" /* webpackChunkName: "component---src-pages-company-job-description-jsx" */),
  "component---src-pages-company-life-at-5-c-jsx": () => import("./../../../src/pages/company/life-at-5c.jsx" /* webpackChunkName: "component---src-pages-company-life-at-5-c-jsx" */),
  "component---src-pages-company-reach-us-jsx": () => import("./../../../src/pages/company/reach-us.jsx" /* webpackChunkName: "component---src-pages-company-reach-us-jsx" */),
  "component---src-pages-compliance-jsx": () => import("./../../../src/pages/compliance.jsx" /* webpackChunkName: "component---src-pages-compliance-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-impact-blogs-jsx": () => import("./../../../src/pages/impact/blogs.jsx" /* webpackChunkName: "component---src-pages-impact-blogs-jsx" */),
  "component---src-pages-impact-case-study-jsx": () => import("./../../../src/pages/impact/case-study.jsx" /* webpackChunkName: "component---src-pages-impact-case-study-jsx" */),
  "component---src-pages-impact-info-blog-jsx": () => import("./../../../src/pages/impact/info-blog.jsx" /* webpackChunkName: "component---src-pages-impact-info-blog-jsx" */),
  "component---src-pages-impact-newsroom-jsx": () => import("./../../../src/pages/impact/newsroom.jsx" /* webpackChunkName: "component---src-pages-impact-newsroom-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-rsna-2024-jsx": () => import("./../../../src/pages/RSNA-2024.jsx" /* webpackChunkName: "component---src-pages-rsna-2024-jsx" */),
  "component---src-pages-sign-in-jsx": () => import("./../../../src/pages/sign-in.jsx" /* webpackChunkName: "component---src-pages-sign-in-jsx" */)
}

